
<div class="position-relative">
  <div class="navb-icons d-flex px-2">
    <!--            <div class="fas fa-search" id="search-btn" @click.prevent="openComponent('search')"></div>-->
    <div id="login-btn" @click.prevent="openComponent('login')">
      <svg id="Layer_2" style="width: 35px;height:35px;fill:#2B4D9B"
           data-name="Layer 2"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.14 511.21">
        <g id="Layer_5" data-name="Layer 5">
          <path
              d="m0,271.66C0,261.01,0,250.37,0,239.72c1.39-10.18,2.4-20.43,4.21-30.53C31.93,54.31,196.07-39.04,344.05,15.84c121.31,44.99,189.76,172.49,160.26,298.52-23.24,99.27-104.87,176.26-204.72,192.98-9.31,1.56-18.7,2.6-28.06,3.87h-31.95c-1.11-.27-2.21-.64-3.34-.8-11.66-1.7-23.44-2.83-34.97-5.14C102.2,485.45,20.06,400.2,4,300.72,2.44,291.07,1.32,281.35,0,271.66Zm409,148.84c65.96-60.26,93.13-160.73,54.33-252.56C425.18,77.66,330.28,17.87,224.58,32.28,128.01,45.44,47.56,121.17,32.38,222.76c-5.48,36.66-2.05,72.62,10.35,107.62,12.34,34.83,32.06,64.78,59.46,90.49,7.09-24.14,18.47-44.8,34.56-62.81,16.04-17.95,35.28-31.51,58.07-41.24-28.38-19.79-45.48-46.03-49.51-79.75-4.05-33.91,5.57-64.01,28.92-89.05,41.78-44.78,111.04-47.71,155.81-6.87,21.56,19.66,34.17,44.14,36.21,73.28,3.03,43.23-14.66,76.99-49.54,102.4,46.46,20.78,77.09,55.09,92.3,103.67Zm-153.81,60.82c44.63-.34,85.49-12.33,122.92-36.2,3.93-2.51,4.82-4.88,3.8-9.24-14.8-63.66-71.85-105.61-136.97-100.55-56.66,4.4-104.13,45.94-115.93,101.35-.74,3.48-.25,5.64,3.05,7.77,37.58,24.28,78.72,36.47,123.13,36.87Zm.45-338.96c-44.78-.09-81.19,36.28-81.25,81.16-.06,44.9,36.17,81.2,81.08,81.25,44.92.04,81.19-36.15,81.25-81.08.06-44.85-36.21-81.24-81.08-81.33Z"/>
        </g>
      </svg>
    </div>
  </div>

  <form id="ignore" class="login-form" :class="{'active': components.login}" v-if="!$contact">
    <template v-if="components.login">
      <h3 id="ignore" class="navb-h3">Inicia sesión</h3>
      <input id="ignore" type="email" v-model="contactLogin.email" placeholder="Tú correo" class="navb-box"
             @keyup.enter="login()">
      <input id="ignore" type="password" v-model="contactLogin.password" placeholder="Tú contraseña" class="navb-box"
             @keyup.enter="login()">
      <input id="ignore" v-if="!status.login" type="submit" value="Entrar" class="main-button"
             @click.prevent="login()"/>
      <div id="ignore" class="spinner-grow" role="status" v-else>
        <span class="sr-only">Loading...</span>
      </div>
      <p id="ignore" class="navb-p">
        ¿No has creado una cuenta?
      </p>
      <a class="navb-a" href="#" @click.prevent="openModal()">Crear cuenta</a>
    </template>
  </form>
  <form id="ignore" class="login-form" :class="{'active': components.login}" v-else>
    <template v-if="components.login">
      <h3 id="ignore" class="navb-h3">{{ $contact.full_name }}</h3>
      <input id="ignore" type="submit"
             value="Mi perfil"
             class="mb-2 main-button w-100"
             @click.prevent="redirect('/perfil')"/>
      <input id="ignore" type="submit"
             value="Cambiar contraseña"
             class="mb-2 main-button w-100"
             @click.prevent="redirect('/perfil?page=change-password')"/>
      <input id="ignore" type="submit"
             value="Cerrar sesión"
             class="mb-2 main-button w-100"
             @click.prevent="logout()"/>
    </template>
  </form>

  <RegisterModal
      ref="registerModal"/>
</div>
